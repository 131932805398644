import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";

import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  list: {
    width: 250,
  },
}));

const NavbarMobileActions = props => {
  const classes = useStyles(props);
  const { isDarkMode, setIsDarkMode, handleNavigate } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleSetDrawerOpen = e => {
    setDrawerOpen(!drawerOpen);
  };

  const handleListPool = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Mobile",
      action: "Click",
      label: "List Pool",
      value: 40,
    });

    const listPoolLink = document.createElement("a");
    listPoolLink.href = "https://forms.gle/vTf15JMhFydYdKA8A";
    listPoolLink.target = "_blank";
    listPoolLink.rel = "noopener noreferrer";
    listPoolLink.click();
  };

  const handleUpdatePool = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Mobile",
      action: "Click",
      label: "Update Pool",
      value: 40,
    });

    const updatePoolLink = document.createElement("a");
    updatePoolLink.href = "https://forms.gle/SCCUZRUPAaqTTC959";
    updatePoolLink.target = "_blank";
    updatePoolLink.rel = "noopener noreferrer";
    updatePoolLink.click();
  };

  const handleListCoin = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Mobile",
      action: "Click",
      label: "List Coin",
      value: 40,
    });

    const listCoinLink = document.createElement("a");
    listCoinLink.href = "https://forms.gle/6JGHC6zj8oUnabHB6";
    listCoinLink.target = "_blank";
    listCoinLink.rel = "noopener noreferrer";
    listCoinLink.click();
  };

  const handleUpdateCoin = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Mobile",
      action: "Click",
      label: "Update Coin",
      value: 40,
    });

    const updateCoinLink = document.createElement("a");
    updateCoinLink.href = "https://forms.gle/w6HsQhnM3c8cDg918";
    updateCoinLink.target = "_blank";
    updateCoinLink.rel = "noopener noreferrer";
    updateCoinLink.click();
  };

  const handleSendFeedback = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Mobile",
      action: "Click",
      label: "Send Feedback",
      value: 40,
    });

    const subject = "Feedback WhereToMine";
    const emailBody = "Feedback: ";
    document.location = "mailto:wheretomineio@gmail.com?subject=" + subject + "&body=" + emailBody;
  };

  return (
    <div className={classes.root}>
      <IconButton aria-label="search" color="inherit" onClick={e => handleNavigate("/search")}>
        <SearchIcon />
      </IconButton>
      <IconButton aria-label="toggle dark mode" color="inherit" onClick={setIsDarkMode}>
        {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
      <IconButton aria-label="search" edge="end" color="inherit" onClick={handleSetDrawerOpen}>
        <MenuOpenIcon />
      </IconButton>

      <Drawer anchor="right" open={drawerOpen} onClose={handleSetDrawerOpen}>
        <div className={classes.list}>
          <List>
            <ListItem button onClick={e => handleNavigate("/")} style={{ marginTop: 90 }}>
              <ListItemText primary={"Coins"} />
            </ListItem>
            <ListItem button onClick={e => handleNavigate("/algorithms")}>
              <ListItemText primary={"Algorithms"} />
            </ListItem>
            <ListItem button onClick={e => handleNavigate("/pools")}>
              <ListItemText primary={"Pools"} />
            </ListItem>
            <Divider />
            <ListItem button onClick={e => handleNavigate("/advertise")}>
              <ListItemText primary={"Advertise"} />
            </ListItem>
            <ListItem button onClick={e => handleNavigate("/about")}>
              <ListItemText primary={"About"} />
            </ListItem>
            <ListItem button onClick={e => handleNavigate("/contact")}>
              <ListItemText primary={"Contact"} />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText primary={"List Pool"} onClick={handleListPool} />
            </ListItem>
            <ListItem button>
              <ListItemText primary={"Update Pool Info"} onClick={handleUpdatePool} />
            </ListItem>
            <ListItem button>
              <ListItemText primary={"List Coin"} onClick={handleListCoin} />
            </ListItem>
            <ListItem button>
              <ListItemText primary={"Update Coin Info"} onClick={handleUpdateCoin} />
            </ListItem>
            <ListItem button>
              <ListItemText primary={"Send Feedback"} onClick={handleSendFeedback} />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default NavbarMobileActions;
