import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  link: {
    padding: "10px",
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "color .1s ease-out",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  linkTitle: {
    fontSize: "1rem",
    fontWeight: "600",
    letterSpacing: ".04em",
    textTransform: "none",
  },
  menuButton: {
    background: theme.palette.background.default,
    "&:hover": {
      textDecoration: "none",
      background: theme.palette.background.default,
    },
  },
}));

const NavbarDesktopActions = props => {
  const classes = useStyles(useStyles);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isDarkMode, setIsDarkMode, handleNavigate } = props;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListPool = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Desktop",
      action: "Click",
      label: "List Pool",
      value: 40,
    });

    const listPoolLink = document.createElement("a");
    listPoolLink.href = "https://forms.gle/vTf15JMhFydYdKA8A";
    listPoolLink.target = "_blank";
    listPoolLink.rel = "noopener noreferrer";
    listPoolLink.click();
    setAnchorEl(null);
  };

  const handleUpdatePool = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Desktop",
      action: "Click",
      label: "Update Pool",
      value: 40,
    });

    const updatePoolLink = document.createElement("a");
    updatePoolLink.href = "https://forms.gle/SCCUZRUPAaqTTC959";
    updatePoolLink.target = "_blank";
    updatePoolLink.rel = "noopener noreferrer";
    updatePoolLink.click();
    setAnchorEl(null);
  };

  const handleListCoin = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Desktop",
      action: "Click",
      label: "List Coin",
      value: 40,
    });

    const listCoinLink = document.createElement("a");
    listCoinLink.href = "https://forms.gle/6JGHC6zj8oUnabHB6";
    listCoinLink.target = "_blank";
    listCoinLink.rel = "noopener noreferrer";
    listCoinLink.click();
    setAnchorEl(null);
  };

  const handleUpdateCoin = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Desktop",
      action: "Click",
      label: "Update Coin",
      value: 40,
    });

    const updateCoinLink = document.createElement("a");
    updateCoinLink.href = "https://forms.gle/w6HsQhnM3c8cDg918";
    updateCoinLink.target = "_blank";
    updateCoinLink.rel = "noopener noreferrer";
    updateCoinLink.click();
    setAnchorEl(null);
  };

  const handleSendFeedback = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Desktop",
      action: "Click",
      label: "Send Feedback",
      value: 40,
    });

    const subject = "Feedback WhereToMine";
    const emailBody = "Feedback: ";
    document.location = "mailto:wheretomineio@gmail.com?subject=" + subject + "&body=" + emailBody;
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Link className={classes.link} to="/">
        <Typography className={classes.linkTitle} variant="button">
          Coins
        </Typography>
      </Link>
      <Link className={classes.link} to="/algorithms">
        <Typography className={classes.linkTitle} variant="button">
          Algorithms
        </Typography>
      </Link>
      <Link className={classes.link} to="/pools">
        <Typography className={classes.linkTitle} variant="button">
          Pools
        </Typography>
      </Link>
      <Button className={clsx(classes.link, classes.linkTitle, classes.menuButton)} onClick={handleClick}>
        Resources
      </Button>
      <IconButton aria-label="search" color="inherit" onClick={e => handleNavigate("/search")}>
        <SearchIcon />
      </IconButton>
      <IconButton aria-label="toggle dark mode" edge="end" color="inherit" onClick={setIsDarkMode}>
        {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleListPool}>List Pool</MenuItem>
        <MenuItem onClick={handleUpdatePool}>UpdatePool</MenuItem>
        <MenuItem onClick={handleListCoin}>List Coin</MenuItem>
        <MenuItem onClick={handleUpdateCoin}>Update Coin</MenuItem>
        <MenuItem onClick={handleSendFeedback}>Send Feedback</MenuItem>
      </Menu>
    </div>
  );
};

export default NavbarDesktopActions;
