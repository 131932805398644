import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { makeStyles } from "@material-ui/core/styles";
import "./layout.css";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 1080,
    background: theme.palette.background.default,
  },
  navajo: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  main: {
    paddingBottom: 80,
  },
}));

const App = ({ children, isDarkMode, updateDarkMode }) => {
  const classes = useStyles();

  useEffect(() => {
    const elements = document.getElementsByClassName("header-5905eb278afaba84635");
    if (elements.length === 1) {
      elements[0].style.zIndex = "1 !important";
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className="header-5905eb278afaba84635" style={{ zIndex: "1 !important" }}></div>

      <Navbar isDarkMode={isDarkMode} updateDarkMode={updateDarkMode} />
      {/* Navajo Mobile */}
      <main className={classes.main}>{children}</main>
      {/* Navajo Mobile */}
      <Footer />
    </div>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
