import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CookiesProvider, useCookies } from "react-cookie";
import App from "./App";

const themeLight = createMuiTheme({
  palette: {
    border: {
      gradient: {
        main: "#D2D2D2",
        side: "#FFFFFF",
      },
    },
    primary: {
      main: "#2164e8",
    },
    background: {
      hover: "#e4e6e9",
      default: "#ffffff",
      paper: "#f0f0f0",
      paperTwo: "#fafafb",
      line: "#e2e2e2",
    },
    type: "light",
  },
  typography: {
    fontFamily: [
      "Lato",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    subtitle3: {
      fontWeight: 400,
      fontSize: "0.6rem",
      lineHeight: 1,
      letterSpacing: "0.03333em",
    },
  },
});

const themeDark = createMuiTheme({
  palette: {
    border: {
      gradient: {
        main: "#092552",
        side: "#020812",
      },
    },
    primary: {
      main: "#2164e8",
    },
    background: {
      hover: "#303031",
      default: "#020812",
      paper: "#041229",
      paperTwo: "#2b2d2e",
      line: "#2b2d2e",
    },
    type: "dark",
  },
  typography: {
    fontFamily: [
      "Lato",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    subtitle3: {
      fontWeight: 400,
      fontSize: "0.6rem",
      lineHeight: 1,
      letterSpacing: "0.03333em",
    },
  },
});

const Root = ({ children }) => {
  const [cookies, setCookies] = useCookies(["paletteType"]);

  const paletteType = cookies.paletteType ? cookies.paletteType : "light";

  const [isDarkMode, setDarkMode] = useState(paletteType === "dark" ? true : false);

  const updateDarkMode = darkMode => {
    setDarkMode(darkMode);
    setCookies("paletteType", darkMode ? "dark" : "light", { path: "/" });
  };

  return (
    <CookiesProvider>
      <MuiThemeProvider theme={isDarkMode ? themeDark : themeLight}>
        <React.Fragment>
          <CssBaseline />
          <App children={children} isDarkMode={isDarkMode} updateDarkMode={updateDarkMode} />
        </React.Fragment>
      </MuiThemeProvider>
    </CookiesProvider>
  );
};

export default Root;
