import React from "react";
import { navigate } from "gatsby";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import NavajoNavBar from "../Navajo/NavajoNavBar";
import Typography from "@material-ui/core/Typography";
import NavbarDesktopActions from "./NavbarDesktopActions";
import NavbarMobileActions from "./NavbarMobileActions";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: "unset",
  },
  whereToMine: {
    flexGrow: 1,
    fontWeight: "600",
    fontSize: "1rem",
    color: theme.palette.text.primary,
    textTransform: "none",
    textDecoration: "none",
    "&:hover": {
      textTransform: "none",
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
}));

const Navbar = props => {
  const { isDarkMode, updateDarkMode } = props;
  const classes = useStyles(props);

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "wheretomine-icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const handleUpdateDarkMode = e => {
    updateDarkMode(!isDarkMode);
  };

  const handleNavigate = to => {
    navigate(to);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <Link to="/">
            <Img
              fluid={data.file.childImageSharp.fluid}
              style={{ height: 40, width: 40, borderRadius: 4 }}
              title="WhereToMine Logo"
              alt="WhereToMine Logo"
            />
          </Link>

          <div style={{ flex: 1, marginLeft: 16 }}>
            <Link to="/" className={classes.whereToMine}>
              <Typography variant="h6" className={classes.whereToMine}>
                WhereToMine
              </Typography>
            </Link>
          </div>
          {/* <NavajoNavBar /> */}

          <NavbarDesktopActions isDarkMode={isDarkMode} setIsDarkMode={handleUpdateDarkMode} handleNavigate={handleNavigate} />
          <NavbarMobileActions isDarkMode={isDarkMode} setIsDarkMode={handleUpdateDarkMode} handleNavigate={handleNavigate} />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
