import React from "react";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: "4em",
    paddingBottom: "4em",
    /* eslint-disable no-dupe-keys */
    background: theme.palette.background.default,
    background: "rgb(57, 99, 237)",
    background: "-moz-linear-gradient(142deg, rgba(57, 99, 237, 1) 0%, rgba(125, 69, 228, 1) 100%)",
    background: "-webkit-linear-gradient(142deg, rgba(57, 99, 237, 1) 0%, rgba(125, 69, 228, 1) 100%)",
    background: "linear-gradient(142deg, rgba(57, 99, 237, 1) 0%, rgba(125, 69, 228, 1) 100%)",
    filter: `progid:DXImageTransform.Microsoft.gradient(startColorstr="#3963ed", endColorstr="#7d45e4", GradientType=1)`,
  },
  grid: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  title: {
    fontSize: "1.1rem",
    fontWeight: "600",
    lineHeight: "1.2rem",
    letterSpacing: ".04em",
    color: theme.palette.common.white,
    marginBottom: 16,
  },
  subtitle: {
    fontSize: "0.85rem",
    fontWeight: "500",
    lineHeight: "1rem",
    letterSpacing: ".04em",
    textTransform: "none",
    textDecoration: "none",
    color: theme.palette.common.white,
    opacity: 0.8,
    marginBottom: 8,
    "&:hover": {
      opacity: 1,
    },
    cursor: "pointer",
  },
}));

const Footer = () => {
  const classes = useStyles();

  const handleListPool = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Footer",
      action: "Click",
      label: "List Pool",
      value: 40,
    });

    const listPoolLink = document.createElement("a");
    listPoolLink.href = "https://forms.gle/vTf15JMhFydYdKA8A";
    listPoolLink.target = "_blank";
    listPoolLink.rel = "noopener noreferrer";
    listPoolLink.click();
  };

  const handleUpdatePool = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Footer",
      action: "Click",
      label: "Update Pool",
      value: 40,
    });

    const updatePoolLink = document.createElement("a");
    updatePoolLink.href = "https://forms.gle/SCCUZRUPAaqTTC959";
    updatePoolLink.target = "_blank";
    updatePoolLink.rel = "noopener noreferrer";
    updatePoolLink.click();
  };

  const handleListCoin = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Footer",
      action: "Click",
      label: "List Coin",
      value: 40,
    });

    const listCoinLink = document.createElement("a");
    listCoinLink.href = "https://forms.gle/6JGHC6zj8oUnabHB6";
    listCoinLink.target = "_blank";
    listCoinLink.rel = "noopener noreferrer";
    listCoinLink.click();
  };

  const handleUpdateCoin = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Footer",
      action: "Click",
      label: "Update Coin",
      value: 40,
    });

    const updateCoinLink = document.createElement("a");
    updateCoinLink.href = "https://forms.gle/w6HsQhnM3c8cDg918";
    updateCoinLink.target = "_blank";
    updateCoinLink.rel = "noopener noreferrer";
    updateCoinLink.click();
  };

  const handleSendFeedback = e => {
    e.preventDefault();

    trackCustomEvent({
      category: "Resources Footer",
      action: "Click",
      label: "Send Feedback",
      value: 40,
    });

    const subject = "Feedback WhereToMine";
    const emailBody = "Feedback: ";
    document.location = "mailto:wheretomineio@gmail.com?subject=" + subject + "&body=" + emailBody;
  };

  return (
    <footer className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={4} className={classes.grid}>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.title} variant="subtitle1" gutterBottom>
              Resources
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle2" gutterBottom onClick={handleListPool}>
              List a pool
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle2" gutterBottom onClick={handleUpdatePool}>
              Update a pool
            </Typography>
            <Link to="/verified" style={{ textDecoration: "none" }}>
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                Verify a pool
              </Typography>
            </Link>
            <Typography className={classes.subtitle} variant="subtitle2" gutterBottom onClick={handleListCoin}>
              List a coin
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle2" gutterBottom onClick={handleUpdateCoin}>
              Update a coin
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.title} variant="subtitle1" gutterBottom>
              Company
            </Typography>
            <Link to="/advertise" style={{ textDecoration: "none" }}>
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                Advertise
              </Typography>
            </Link>
            <Link to="/about" style={{ textDecoration: "none" }}>
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                About us
              </Typography>
            </Link>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                Contact
              </Typography>
            </Link>
            <Typography className={classes.subtitle} variant="subtitle2" gutterBottom onClick={handleSendFeedback}>
              Feedback
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.title} variant="subtitle1" gutterBottom>
              Social Media
            </Typography>
            <a className={classes.subtitle} href="https://twitter.com/wheretomineio" target="_blank" rel="noopener noreferrer">
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                Twitter
              </Typography>
            </a>
            <a className={classes.subtitle} href="https://discord.gg/Ekd7UDd" target="_blank" rel="noopener noreferrer">
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                Discord
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.title} variant="subtitle1" gutterBottom>
              Our Apps
            </Typography>
            <a
              className={classes.subtitle}
              href="https://itunes.apple.com/app/wheretomine/id1399461885"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                WhereToMine
              </Typography>
            </a>
            <a
              className={classes.subtitle}
              href="https://apps.apple.com/us/app/blockwatch-x-mining-monitor/id1481122637"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                Blockwatch X Mining Monitor
              </Typography>
            </a>
            <a
              className={classes.subtitle}
              href="https://itunes.apple.com/app/ethermine-mining-monitor/id1367242223"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                Ethermine Mining Monitor
              </Typography>
            </a>
            <a
              className={classes.subtitle}
              href="https://apps.apple.com/us/app/nanopool-mining-monitor/id1451054479"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                Nanopool Mining Monitor
              </Typography>
            </a>
            <a
              className={classes.subtitle}
              href="https://apps.apple.com/nz/app/flypool-mining-monitor/id1450724913"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                FlyPool Mining Monitor
              </Typography>
            </a>
            <a
              className={classes.subtitle}
              href="https://apps.apple.com/sg/app/dwarfpool-mining-monitor/id1451507739"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography className={classes.subtitle} variant="subtitle2" gutterBottom>
                Dwarfpool Mining Monitor
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.title} variant="subtitle1" gutterBottom>
              WhereToMine
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle2" gutterBottom style={{ cursor: "default", opacity: 1 }}>
              View the best cryptocurrency mining pools for you favorite coins like Bitcoin, Ethereum, Monero, Zcash.
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle2" gutterBottom style={{ opacity: 1 }}>
              <a className={classes.subtitle} href="https://coincap.io/" target="_blank" rel="noopener noreferrer" style={{ opacity: 1 }}>
                Market Data by coincap.io
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption" style={{ color: "white" }}>
              WhereToMine © 2021
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">
              <a
                href="https://wheretomine.io/sitemap.xml"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white", textDecoration: "none", marginRight: 16 }}
              >
                Sitemap
              </a>
            </Typography>
            <Typography variant="caption">
              <a
                href="https://www.iubenda.com/privacy-policy/13075849"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white", textDecoration: "none", marginRight: 16 }}
              >
                Privacy Policy
              </a>
            </Typography>
            <Typography variant="caption">
              <a
                href="https://wheretomine.io/terms/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white", textDecoration: "none", marginRight: 16 }}
              >
                Terms & Conditions
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
